import React, { Fragment } from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../../components/common/Layout/layout"
import SEO from "../../components/common/SEO/seo"
import BannerPage from "../../components/banner/BannerPage/bannerPage"
import SectionTextImage from "../../components/section/TextImage/textImage"
import CallToAction from "../../components/section/CallToAction/callToAction"

const AuditoriasOnlinePage = () => {
    const data = useStaticQuery(graphql`
    query {
      auditoriasOnlineImage1: file(relativePath: { eq: "images/sections/marketing-digital/auditorias-online/que-es-auditoria-online-ibxagency.png" }) {
        childImageSharp {
          fluid(maxWidth: 854) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      auditoriasOnlineImage2: file(relativePath: { eq: "images/sections/marketing-digital/auditorias-online/tipos-auditoria-online-ibxagency.png" }) {
        childImageSharp {
          fluid(maxWidth: 854) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      auditoriasOnlineImage3: file(relativePath: { eq: "images/sections/marketing-digital/auditorias-online/que-permiten-auditorias-online-ibxagency.png" }) {
        childImageSharp {
          fluid(maxWidth: 854) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

    return (
        <Layout>
            <SEO
                title="Auditorías Páginas Web"
                description="Agencia de Marketing Digital en Barcelona experta en auditorías online de páginas web y tiendas online para saber si esas páginas están bien posicionadas."
            />

            <BannerPage
                title="Auditorías y asesoramiento online"
                text="Auditoría de palabras clave, presencia web, optimización web y competencia online"
                color="green"
            />

            <SectionTextImage
                title1="¿Qué es una "
                title2="auditoría online?"
                text={<Fragment>
                    La <strong>auditoría online</strong> nos permite descubrir quién es nuestro cliente y qué presencia tiene en la red. Así, podemos asesorar globalmente al cliente de las acciones que tienen que implementar. <br /><br />
                    ¿Quieres saber si tu web está posicionada en Internet de forma óptima para que te encuentren tus clientes?
                    </Fragment>}
                image={data.auditoriasOnlineImage1.childImageSharp.fluid}
                imageAlt="¿Qué es una auditoria web? - Agencia Marketing Digital"
                color="green"
                containerTextRight={false}
            />

            <SectionTextImage
                title1="IBX y sus 4 tipos "
                title2="de auditorías"
                text={<Fragment>
                    <ul class="text-left">
                        <li>Auditoría de <strong>palabras clave</strong></li>
                        <li>Auditoría de <strong>presencia web</strong></li>
                        <li>Auditoría de <strong>optimización SEO</strong></li>
                        <li>Auditoría de la <strong>competencia</strong></li>
                    </ul>
                </Fragment>}
                image={data.auditoriasOnlineImage2.childImageSharp.fluid}
                imageAlt="Tipos de auditorías web - Agencia Marketing Digital"
                color="green"
                containerTextRight={true}
            />

            <SectionTextImage
                title1="¿Que permiten "
                title2="las auditorías?"
                text={<Fragment>
                    <ul class="text-left">
                        <li>Averiguar cuáles son las <strong>keywords</strong> más utilizadas.</li>
                        <li><strong>Saber cómo nos ven</strong> los usuarios y qué dicen de nosotros.</li>
                        <li>Conocer que <strong>presencia</strong> web y social tenemos.</li>
                        <li>Percibir cuál es la <strong>reputación online</strong> de nuestra marca.</li>
                        <li>Las <strong>limitaciones técnicas</strong> de nuestra web o tienda online.</li>
                        <li>Examinar si un sitio web está <strong>optimizado en SEO</strong> o no. </li>
                        <li>Saber cuál es nuestra <strong>competencia</strong> y qué canales utiliza para darse a conocer.</li>
                        <li>Comprobar si el <strong>contenido</strong> de nuestra web tiene concordancia con nuestra marca.</li>
                    </ul>
                </Fragment>}
                image={data.auditoriasOnlineImage3.childImageSharp.fluid}
                imageAlt="¿Qué permiten las auditorías web? - Agencia Marketing Digital"
                color="green"
                containerTextRight={false}
            />

            <CallToAction
                color="green"
                title="¿Quieres que elaboremos una auditoría de tu web?"
                text={<Fragment>
                    ¡Somos tu <strong>agencia de marketing de digital experta en auditorías online</strong>! <br />
                    ¡Dínos tu dominio, le echaremos una ojeada!
                </Fragment>}
                button="¿Analizamos tu web?"
                buttonUrl="/contacto"
            />

        </Layout>
    )

}
export default AuditoriasOnlinePage
